<template>
    <div>
        <ul  class="nav">
            <li  :class="{'active':pathName.endsWith('aboutus')} " @click='changPath("aboutus")' >
                <span >关于我们</span>
            </li>
            <li :class="{'active':pathName.endsWith('contact')}" @click='changPath("contact")'><span>联系我们</span></li>
            <li :class="{'active':pathName.endsWith('disclaimer')}" @click='changPath("disclaimer")'><span>免责声明</span></li>
            <li :class="{'active':pathName.endsWith('userHelp')}" @click='changPath("userHelp")'><span>用户帮助</span></li>
            <li :class="{'active':pathName.endsWith('userContent')}" @click='changPath("userContent")'><span>用户协议</span></li>
            <li :class="{'active':pathName.endsWith('privacyPolicy')}" @click='changPath("privacyPolicy")'><span>隐私权政策</span></li>
            <li :class="{'active':pathName.endsWith('feedback')}" @click='changPath("feedback")'><span>意见反馈</span></li>
        </ul>
    </div>
</template>
<script>
import { ref } from '@vue/reactivity';
import { useRoute, useRouter } from 'vue-router'
export default {
    setup() {
        const route=useRoute();
        const router=useRouter();
        console.log(route.path);
        let pathName=ref(route.path);
        const changPath=(name)=>{
            router.push('/help/'+name);
        }
        return{
            pathName,
            changPath
        }
    },
}
</script>
<style lang="scss" scoped>
@import '@/styles/variables.scss';
.nav {
    margin-bottom: 30px;
}
.nav > li {
    position: relative;
    display: block;
    span {
        position: relative;
        display: block;
        padding: 8px 10px 8px 32px;
        line-height: 24px;
        font-weight: normal;
        text-transform: none;
        cursor: pointer;
        -webkit-transition: background-color 0.2s ease-in-out 0s;
        transition: background-color 0.2s ease-in-out 0s;
    }
    &.active{
        background-color: $themeColor;
        color: #fff;
        border-radius: 2px;
    }
}
</style>